import { reactive, ref, computed } from 'vue';
export default {
  setup() {
    const kinds = reactive([{
      text: '百度',
      src: 'https://www.baidu.com/s?tn=68018901_20_oem_dg&ie=utf-8&wd=',
      icon: require('@/assets/icon/searchbd.png')
    }, {
      text: '360',
      src: 'https://www.so.com/s?q=',
      icon: require('@/assets/icon/search360.png')
    }, {
      text: 'Github',
      src: 'https://github.com/search?q=',
      icon: require('@/assets/icon/searchgithub.png')
    }, {
      text: '简书',
      src: 'https://www.jianshu.com/search?q=',
      icon: require('@/assets/icon/jiansu.png')
    }, {
      text: '掘金',
      src: 'https://juejin.cn/search?query=',
      icon: require('@/assets/icon/juejing.png')
    }, {
      text: '知乎',
      src: 'https://www.zhihu.com/search?type=content&q=',
      icon: require('@/assets/icon/searchzhihu.png')
    }, {
      text: 'CSDN',
      src: 'https://so.csdn.net/so/search?spm=1000.2115.3001.4498&q=',
      icon: require('@/assets/icon/csdn.png')
    }]);
    let searVal = ref('');
    let activeIdx = ref(0);
    const activeIcon = computed(() => kinds[activeIdx.value].icon);
    function searchEvent() {
      if (searVal.value) {
        window.open(kinds[activeIdx.value].src + searVal.value);
        searVal.value = '';
      }
    }
    return {
      kinds,
      searVal,
      activeIdx,
      activeIcon,
      searchEvent
    };
  }
};