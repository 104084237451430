import topSearch from './components/topSearch.vue';
import pathNav from './components/pathNav.vue';
import cardMes from '@/components/cardMes.vue';
import bottomWeb from '@/components/bottomWeb.vue';
import { useNavPathStore } from '@/store/modules/navPath';
import { reactive, toRefs, ref } from 'vue';
export default {
  components: {
    pathNav,
    topSearch,
    cardMes,
    bottomWeb
  },
  setup() {
    const pick = ref();
    const flags = reactive({
      iconFlag: true,
      introFlag: true
    });
    function aniHandler(idx) {
      const pickEls = pick.value;
      const el = pickEls[idx];
      pickEls.forEach(item => item.className = 'pick');
      el.classList.add('animate__animated', 'animate__bounce', 'animate__delay-1s');
    }
    const store = useNavPathStore();
    return {
      pick,
      store,
      flags,
      ...toRefs(flags),
      aniHandler
    };
  }
};