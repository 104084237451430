import { ref } from 'vue';
import { useNavPathStore } from '@/store/modules/navPath';
export default {
  emits: ['aniHandler'],
  setup(props, ctx) {
    const showFlag = ref(true);
    const store = useNavPathStore();
    function maoDianEvent(id, idx) {
      const maoDianEl = document.querySelector(`#${id}`);
      maoDianEl.scrollIntoView();
      ctx.emit('aniHandler', idx);
    }
    function backTop() {
      document.documentElement.scrollTop = 0;
    }
    function resizeHandler() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width > 1350 && height > 600) {
        showFlag.value = true;
      } else {
        showFlag.value = false;
      }
    }
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    return {
      store,
      showFlag,
      backTop,
      maoDianEvent
    };
  }
};