import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/images/logo.png';
const _withScopeId = n => (_pushScopeId("data-v-419a4c8e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "path-nav"
};
const _hoisted_2 = {
  class: "top-icon"
};
const _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $setup.showFlag ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [_createElementVNode("li", _hoisted_2, [_createElementVNode("img", {
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.backTop && $setup.backTop(...args)),
    src: _imports_0,
    class: "logo-img"
  })]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.store.configPath, (item, idx) => {
    return _openBlock(), _createElementBlock("li", {
      onClick: $event => $setup.maoDianEvent(item.id, idx),
      key: idx,
      class: "to-item"
    }, _toDisplayString(item.title), 9, _hoisted_3);
  }), 128))])) : _createCommentVNode("", true);
}